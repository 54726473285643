import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import './css/index.css';
import FormPage from './pages/FormPage';
import ThanksPage from './pages/ThanksPage';
import TermsPage from './pages/TermsPage';
import PrivacyPolicyPage from './pages/PrivacyPage';
import CompanyPage from './pages/CompanyPage';
import reportWebVitals from './reportWebVitals';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Routes>
        <Route key={"Form"} path="/" element={<FormPage />} />
        <Route key={"Thanks"} path="/thanks" element={<ThanksPage />} />
        <Route key={"Terms"} path="/terms" element={<TermsPage />} />
        <Route key={"Privacy"} path="/privacy" element={<PrivacyPolicyPage />} />
        <Route key={"Company"} path="/company" element={<CompanyPage />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>
);

reportWebVitals();
