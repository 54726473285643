import React from 'react';
import '../css/styles.css'
import { Link } from 'react-router-dom';

const today = new Date()
const year = today.getFullYear()

function Footer() {
  return (
    <footer id="footer" className='footer'>
      <ul className="f_character">
        <li>
          <img src="img/character02.png" alt="" width="80px" />
        </li>
      </ul>
      <ul className="f_navi">
        <li>
          <Link to="/terms">利用規約</Link>
        </li>
        <li>
          <Link to="/privacy">個人情報保護方針</Link>
        </li>
        <li>
          <Link to="/company">運営会社</Link>
        </li>
      </ul>
    </footer>
  );
}

export default Footer;